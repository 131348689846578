import React from 'react';

const ReactNode = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.0432 4.3536C25.0081 3.77145 23.6586 3.77407 22.6226 4.3536C17.4338 7.21711 12.2449 10.0815 7.05523 12.9437C6.04538 13.4749 5.36426 14.5594 5.37513 15.685V32.9866C5.36784 34.1342 6.08407 35.2291 7.11996 35.7531C8.61062 36.5461 10.0589 37.4197 11.5765 38.16C13.3295 39.0213 15.5227 39.2119 17.2972 38.3135C18.7375 37.5689 19.3773 35.9131 19.3755 34.3913C19.3871 28.6813 19.3773 22.9712 19.3799 17.2612C19.4142 17.0074 19.2237 16.7362 18.9469 16.7423C18.2838 16.7318 17.6198 16.7362 16.9567 16.7405C16.7061 16.7168 16.4616 16.925 16.4805 17.1768C16.4715 22.85 16.4823 28.525 16.4751 34.1991C16.4895 34.9839 15.9593 35.7383 15.2029 36.0034C13.4559 36.6157 8.46955 33.3781 8.46955 33.3781C8.28656 33.2933 8.17036 33.0949 8.18829 32.8977C8.18829 27.1895 8.18919 21.4811 8.18829 15.7737C8.16314 15.5437 8.30496 15.3364 8.51628 15.2425C13.688 12.3958 18.857 9.54209 24.0287 6.69371C24.2083 6.57382 24.4588 6.57418 24.638 6.69458C29.8097 9.54209 34.9796 12.3932 40.1522 15.24C40.3643 15.3338 40.5 15.5445 40.4784 15.7737C40.4775 21.4811 40.4802 27.1895 40.4766 32.8969C40.4919 33.0989 40.3876 33.2946 40.1989 33.3833C35.0755 36.2145 29.8938 38.9544 24.8167 41.8678C24.5952 41.9949 24.3443 42.1389 24.0961 41.9989C22.761 41.2604 21.442 40.4931 20.1114 39.7475C19.9667 39.6572 19.7825 39.6185 19.6316 39.7194C19.0349 40.0338 18.4738 40.2885 17.7887 40.5597C16.8264 40.9406 16.9802 41.0964 17.8479 41.602C19.4042 42.4827 20.963 43.3599 22.5192 44.2415C23.5436 44.8808 24.9201 44.9264 25.9776 44.3355C31.1673 41.4756 36.3552 38.6123 41.5458 35.7524C42.579 35.2255 43.2988 34.1349 43.2916 32.9865V15.685C43.3015 14.5822 42.6465 13.5171 41.6671 12.978C36.4603 10.1005 31.2509 7.22932 26.0432 4.3536Z"
      fill="#8CC84B"
    />
    <path
      d="M33.8735 17.3437C31.8367 16.254 29.4136 16.196 27.1501 16.3384C25.5149 16.5 23.7951 16.9512 22.6037 18.1234C21.3818 19.3018 21.1159 21.2046 21.6962 22.7429C22.1122 23.8298 23.1591 24.5368 24.2417 24.9107C25.6364 25.403 27.1011 25.5552 28.5663 25.7075C29.9027 25.8464 31.2396 25.9853 32.5241 26.3824C33.0488 26.5563 33.6598 26.8223 33.8206 27.3931C33.9689 28.0692 33.8063 28.8551 33.2573 29.3248C31.5137 30.7049 26.6636 30.4935 24.9408 29.4073C24.2444 28.9446 23.9442 28.1254 23.8222 27.3448C23.8086 27.1015 23.6128 26.8785 23.3487 26.8969C22.6873 26.8891 22.026 26.8908 21.3647 26.8961C21.1339 26.8767 20.894 27.0409 20.8813 27.2763C20.7455 30.8517 24.0291 32.4104 27.1098 32.7536C28.87 32.9213 30.6687 32.9117 32.4019 32.534C33.7048 32.2373 35.0111 31.6911 35.908 30.6875C37.014 29.468 37.2314 27.6521 36.7354 26.133C36.3562 24.9995 35.2725 24.2724 34.1648 23.8983C32.6584 23.3818 30.873 23.1042 29.5087 22.9742C27.5254 22.7853 25.1688 22.8625 24.6075 21.5593C24.3837 20.8769 24.5741 20.0297 25.2012 19.6099C26.8604 18.4845 30.237 18.634 31.9553 19.4747C32.7443 19.8698 33.2015 20.6812 33.3902 21.5004C33.4252 21.7411 33.5871 21.9921 33.8656 21.9816C34.5224 21.9947 35.1792 21.9851 35.8359 21.986C36.0615 22.0018 36.3086 21.8744 36.3526 21.6435C36.3248 19.9313 35.4505 18.1788 33.8735 17.3437Z"
      fill="#8CC84B"
    />
  </svg>
);

export default ReactNode;
